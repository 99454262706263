
import { getCurrentPanier } from "../../utils";
const panierBadget = {
    namespaced:true,
    state:{
        panierBadget:false,
    },
    mutations:{
         SET_NEW(state, data){
            state.panierBadget = data;
        }
    },
    actions:{
        loadPanierBadge({commit}){
            if(getCurrentPanier() != null && getCurrentPanier().Items != null ){
                let len = getCurrentPanier().Items.length;
                if(len >0){
                    commit('SET_NEW', true);
                }else{
                    commit('SET_NEW', false);
                }
            }else{
                commit('SET_NEW', false);
            }
           
                
           
        }
    }
}
export default panierBadget;
import axios from 'axios';
const apiURL = process.env.VUE_APP_API +"/items"
const items = {
    namespaced:true,
    state:{
        items:[],
        prmotionsItems:[],
        subItems:[],
        allItems:[],
        recomendationItems:[],
    },
    mutations:{
        SET_ITEMS(state, data){
            state.items = data;
            var tomAr = [];
            data.forEach(element => {
                if(element.IsSold){
                    tomAr.push(element);
                }
            });
            state.prmotionsItems = tomAr;
        },
        SET_SUBITEMS(state, data){
            state.subItems = data;
          
        }, 
        SET_AllITEMS(state, data){
            state.allItems = data;
          
        },
        SET_RECITEMS(state, data){
            state.recomendationItems = data;
          
        },
    },
    actions:{
        loadItems({commit}){
            axios.get(apiURL).then(res =>{
                commit('SET_ITEMS', res.data);
            }).catch(err=>{
            })
        },
        loadSubItems({commit},payload){
            axios.get(apiURL+"/ByCatcl/"+payload.Uid).then(res =>{
                commit('SET_SUBITEMS', res.data);
            }).catch(err=>{
            })
        }, 
        loadAllItems({commit}){
            axios.get(apiURL+"/allcl").then(res =>{
                commit('SET_AllITEMS', res.data);
            }).catch(err=>{
            })
        }, 
        loadRecItems({commit},payload){
            axios.get(apiURL+"/ByCatLimcl/"+payload.CUid).then(res =>{
                commit('SET_RECITEMS', res.data);
            }).catch(err=>{
            })
        },
    }
}
export default items;
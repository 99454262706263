import firebase from "firebase/app";
import "firebase/auth";
import { setCurrentUser } from "../../utils";
import axios from "axios";

const apiURL = process.env.VUE_APP_API + "/users/";
export default {
  namespaced: true,
  state: {
    //currentUser: isAuthGuardActive ? getCurrentUser() : currentUser,
    currentUser: null,
    currentUserFromMonDb: null,
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload;
      state.processing = false;
      state.loginError = null;
    },
    setLogout(state) {
      state.currentUser = null;
      state.processing = false;
      state.loginError = null;
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.loginError = null;
    },
    setError(state, payload) {
      state.loginError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    setForgotMailSuccess(state) {
      state.loginError = null;
      state.currentUser = null;
      state.processing = false;
      state.forgotMailSuccess = true;
    },
    setResetPasswordSuccess(state) {
      state.loginError = null;
      state.currentUser = null;
      state.processing = false;
      state.resetPasswordSuccess = true;
    },
    clearError(state) {
      state.loginError = null;
    },
    SET_USERFROMDB(state, data) {
      if (data.length > 0) {
        state.currentUserFromMonDb = data[0];
      }
    }
  },
  actions: {
    loadUserFromDb({ commit }, payload) {
      axios
        .get(apiURL + "user/" + payload.uid)
        .then(res => {
          commit("SET_USERFROMDB", res.data);
        })
        .catch(err => {});
    },
    login({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return firebase
            .auth()
            .signInWithEmailAndPassword(payload.email, payload.password)
            .then(
              user => {
                /*const item = { uid: user.user.uid, email: user.user.email };
                setCurrentUser(item);
                commit("setUser", item);*/
                axios.get(apiURL + "/user/"+ user.user.uid).then(res => {
                  const item = {
                    uid: res.data.uid,
                    email: res.data.email,
                    phone: res.data.phone,
                    name: res.data.name
                  };
                  setCurrentUser(item);
                  commit("setUser", item);
                });
              },
              err => {
                setCurrentUser(null);
                commit("setError", err.message);
                setTimeout(() => {
                  commit("clearError");
                }, 3000);
              }
            );
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    },
    loginphone({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return firebase
            .auth()
            .signInWithEmailAndPassword(payload.email, payload.password)
            .then(
              user => {
               // const item = { uid: user.user.uid, email: user.user.email };
                
                axios.get(apiURL + "/user/"+ user.user.uid).then(res => {
                  const item = {
                    uid: res.data[0].Uid,
              email: res.data[0].Email,
              phone: res.data[0].Phone,
              name: res.data[0].Name
                  };
                  setCurrentUser(item);
                  commit("setUser", item);
                });
              },
              err => {
                setCurrentUser(null);
                commit("setError", err.message);
                setTimeout(() => {
                  commit("clearError");
                }, 3000);
              }
            );
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    },
    signup({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          return firebase
            .auth()
            .createUserWithEmailAndPassword(payload.email, payload.password)
            .then(
              user => {
                if (user != null) {
                  user.user
                    .updateProfile({
                      displayName: payload.name
                    })
                    .then(() => {
                     
                      try {
                        let tomUser = {
                          Uid: user.user.uid,
                          Name: payload.name,
                          Email: user.user.email,
                          Phone: "",
                          CurrentAddress: ""
                        };
                         axios
                          .post(apiURL + "add/", tomUser)
                          .then(rest => {

                            axios.get(apiURL + "/user/"+ user.user.uid).then(res => {
                              const item = {
                                uid: res.data[0].Uid,
                                email: res.data[0].Email,
                                phone: res.data[0].Phone,
                                name: res.data[0].Name
                              };
                              setCurrentUser(item);
                              commit("setUser", item);
                            });

                          });
                      } catch (err) {}

                      commit("setUser", null);
                    })
                    .catch(error => {});
                }
              },
              err => {
                setCurrentUser(null);
                commit("setError", err.message);
                setTimeout(() => {
                  commit("clearError");
                }, 3000);
              }
            );
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
        });
    },
    signupphone({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);

      try {
        let tomUser = {
          Uid: payload.Uid,
          Name: payload.Name,
          Email: payload.Email,
          Phone: payload.Phone,
          CurrentAddress: ""
        };
         axios.post(apiURL + "add/", tomUser).then(res => {
          axios.get(apiURL + "/user/"+ user.user.uid).then(res => {
            const item = {
              uid: res.data[0].Uid,
              email: res.data[0].Email,
              phone: res.data[0].Phone,
              name: res.data[0].Name
            };
            setCurrentUser(item);
            commit("setUser", item);
          });
        });
      } catch (err) {}
    },
    getUserD({ commit }) {
      commit("clearError");
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          commit("setProcessing", true);
        
          axios.get(apiURL + "/user/"+ user.uid).then(res => {
            const item = {
              uid: res.data[0].Uid,
              email: res.data[0].Email,
              phone: res.data[0].Phone,
              name: res.data[0].Name
            };
            setCurrentUser(item), commit("setUser", item);
          });
         
        }
      });
    },
    forgotPassword({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      firebase
        .auth()
        .sendPasswordResetEmail(payload.email)
        .then(
          user => {
            commit("clearError");
            commit("setForgotMailSuccess");
          },
          err => {
            commit("setError", err.message);
            setTimeout(() => {
              commit("clearError");
            }, 3000);
          }
        );
    },
    resetPassword({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
      firebase
        .auth()
        .confirmPasswordReset(payload.resetPasswordCode, payload.newPassword)
        .then(
          user => {
            commit("clearError");
            commit("setResetPasswordSuccess");
          },
          err => {
            commit("setError", err.message);
            setTimeout(() => {
              commit("clearError");
            }, 3000);
          }
        );
    },
    signOut({ commit }) {
      commit("setProcessing", true);
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            setCurrentUser(null);
            commit("setLogout");
          },
          _error => {}
        );
    }
  }
};

import Vue from 'vue'
import Vuex from 'vuex'

import app from '../main'
import menu from './modules/menu'
import user from './modules/user'
import items from './modules/items'
import categories from './modules/categories'
import newItemsV from './modules/newItems'
import orders from './modules/orders'
import panierBadget from './modules/panier'
import website from './modules/settings'
import { setCurrentLanguage } from '../utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload
      setCurrentLanguage(payload);
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    items,panierBadget,
    menu,website,
    user,
    newItemsV,
    categories,
    orders
  }
})

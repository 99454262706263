import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from "./utils/auth.guard";

Vue.use(VueRouter);
const Search = () =>
  import(/* webpackChunkName: "Search" */ "./views/home/views/Search");
const Details = () =>
  import(/* webpackChunkName: "details" */ "./views/home/views/Details");
const Checkout = () =>
  import(/* webpackChunkName: "group-buying" */ "./views/home/views/Checkout");
const Panier = () =>
  import(/* webpackChunkName: "group-buying" */ "./views/home/views/Panier");
const Aboutus = () =>
  import(/* webpackChunkName: "group-buying" */ "./views/home/views/AboutUs");

const Login = () =>
  import(/* webpackChunkName: "group-login" */ "./views/user/Login");
  const LoginPhone = () =>
    import(/* webpackChunkName: "group-login" */ "./views/user/LoginPhone");
const Register = () =>
  import(/* webpackChunkName: "group-login" */ "./views/user/Register");


  
const History = () =>
import(/* webpackChunkName: "group-profile" */ "./views/home/views/profile/History");
const Orders = () =>
import(/* webpackChunkName: "group-profile" */ "./views/home/views/profile/Orders");
const AccountSettings = () =>
import(/* webpackChunkName: "group-profile" */ "./views/home/views/profile/accountSettings");
const Profile = () =>
import(/* webpackChunkName: "group-profile" */ "./views/home/views/profile/Profile");
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "./views/home")
    // redirect: `${adminRoot}/piaf`,
  },
  {
    path: "/categories/:category",
    name: "categories",
    component: () =>
      import(
        /* webpackChunkName: "Categories" */ "./views/home/views/Categories/Categories"
      )
    // redirect: `${adminRoot}/piaf`,
  },
  {
    path: "/search",
    name: "search",
    component: Search
  },
  {
    path: "/details/:itemId",
    name: "details",
    component: Details,
    props: true
  },
  {
    path: "/checkout/:userId",
    name: "checkout",
    component: Checkout,
    props: true
  },
  {
    path: "/panier",
    name: "panier",
    component: Panier
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: Aboutus
  },
  {
    path: "/profile/:userId",
    name: "profile",
    component: Profile,
    props: true,
    redirect: `/profile/:userId/Orders`,
    children: [
      {
        path: "History",
        name: "History",
        component: History
      },
      {
        path: "Orders",
        name: "Orders",
        component: Orders
      },
      {
        path: "accountSettings",
        name: "accountSettings",
        component:AccountSettings
      }
    ]
  },
  {
    path: "/error",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error")
  },
  {
    path: "/user",
    name: "user",
    component: () => import(/* webpackChunkName: "user" */ "./views/user"),
    redirect: "/user/login",
    children: [
      {
        path: "login",
        component: Login
      },
      {
        path: "loginphone",
        component: LoginPhone
      },
      {
        path: "register",
        component: Register
      }
    ]
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error")
  }
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "hash"
});
router.beforeEach(AuthGuard);
export default router;

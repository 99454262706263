import axios from 'axios';
const apiURL = process.env.VUE_APP_API +"/categories";
const categories = {
    namespaced:true,
    state:{
        categories:[],
        subcategories:[],
    },
    mutations:{
        SET_CATEGORIES(state, data){
            state.categories = data;
        },
        SET_SUBCATEGORIES(state, data){
            state.subcategories = data;
        }
    },
    actions:{
        loadCategories({commit}){
            axios.get(apiURL).then(res =>{
                commit('SET_CATEGORIES', res.data);
            }).catch(err=>{
               
            })
        },
        loadSubCategories({commit},payload){
            axios.get(apiURL+"/"+payload.Uid).then(res =>{
                commit('SET_SUBCATEGORIES', res.data);
            }).catch(err=>{
              
            })
        }
    }
}
export default categories;
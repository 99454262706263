import axios from 'axios';
const apiURL = process.env.VUE_APP_API +"/commandes/ByClient/";
const orders = {
    namespaced:true,
    state:{
        orders:[],
        oldorders:[],
    },
    mutations:{
        SET_ORDERS(state, data){
            state.orders = data.filter(x=> x.State == 0);
            state.oldorders = data.filter(x=> x.State == 1);
        },
       
    },
    actions:{
        loadOrders({commit},payload){
            axios.get(apiURL+payload.uid).then(res =>{
                commit('SET_ORDERS', res.data);
            }).catch(err=>{
            })
        },
     
    }
}
export default orders;
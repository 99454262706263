import axios from 'axios';
const apiURL = process.env.VUE_APP_API +"/"
const newItemsV = {
    namespaced:true,
    state:{
        newItems:[],
    },
    mutations:{
         SET_NEWITEMS(state, data){
            state.newItems = data;
        }
    },
    actions:{
        loadNewItems({commit}){
            axios.get(apiURL+"newItems").then(res =>{
                commit('SET_NEWITEMS', res.data);
            }).catch(err=>{
            })
        }
    }
}
export default newItemsV;
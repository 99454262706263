import { UserRole } from "../utils/auth.roles";

export const defaultMenuType = 'menu-sub-hidden' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const adminRoot = '/app';
export const searchPath = `${adminRoot}/#`
export const buyUrl = 'https://1.envato.market/nEyZa'
export const apiUrl = 'https://api.coloredstrategies.com';

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'fr', name: 'Français', direction: 'ltr' },
  { id: 'العربية', name: 'العربية', direction: 'rtl' }
]

export const firebaseConfig = {
  apiKey: "AIzaSyDh9R4QimaDfQbSCXTbTE8TiugawJQX0cM",
  authDomain: "lareine-3c33b.firebaseapp.com",
  projectId: "lareine-3c33b",
  storageBucket: "lareine-3c33b.appspot.com",
  messagingSenderId: "418909169884",
  appId: "1:418909169884:web:3643e71b0bcf33d586ac19",
  measurementId: "G-VME02ZT3DQ"
};





export const isAuthGuardActive = false;
export const themeRadiusStorageKey = 'theme_radius'
export const themeSelectedColorStorageKey = 'theme_selected_color'
export const defaultColor = 'light.blueolympic'
export const colors = ['bluenavy', 'blueyale', 'blueolympic', 'greenmoss', 'greenlime', 'purplemonster', 'orangecarrot', 'redruby', 'yellowgranola', 'greysteel']

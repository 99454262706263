<template>
  <div class="h-100">
   
   <b-modal  ref="addinstallappmodal"  hide-footer
      hide-header
      id="addinstallappmodal">
    <b-col>
      <b-row>
        <b-col><h5><b> Ajouter l'application à l'écran d'accueil ?</b></h5></b-col>
      </b-row>
      <b-row class="mt-4 mb-2">
        <b-col><b-button block variant="outline-danger" text @click="dismiss"><h6><b> Non</b></h6></b-button></b-col>
         <b-col><b-button block variant="outline-primary" text @click="install"><h6><b>Ajouter</b></h6></b-button></b-col>
      </b-row>
    </b-col>
   </b-modal>
    <router-view />
    <vue-fab
      mainBtnColor="#010000"
      title="Panier"
      icon="shopping_cart"
      size="big"
      :shadow="false"
      :scrollAutoHide="false"
    >
    <fab-item
        @clickItem="GotoAboutUS"
        :idx="1"
        title="Message"
        icon="mail"
        color="#036780"
        titleColor="#ffffff"
        titleBgColor="#036780"
      />
      <fab-item v-if="currentUser != null"
        @clickItem="GotoPanier"
        :idx="0"
        title="Panier"
        icon="shopping_cart"
        color="#806403"
        titleColor="#ffffff"
        titleBgColor="#806403"
      ></fab-item>
      
      <b-badge
        v-if="panierBadget"
        variant="danger"
        style="z-index:997;position: absolute;"
        >•</b-badge
      >
    </vue-fab>
  </div>
  
</template>

<script>
import { getDirection } from "./utils";

import { mapState } from "vuex";

export default {
  data() {
    return {
      deferredPrompt: null
    };
  },
  created() {
    this.$store.dispatch("items/loadItems");
    this.$store.dispatch("categories/loadCategories");
    this.$store.dispatch("newItemsV/loadNewItems");
    this.$store.dispatch("user/getUserD");
    this.$store.dispatch("website/load");

    this.$store.dispatch("panierBadget/loadPanierBadge");
    
    
    
  },
  mounted() {
window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    this.$bvModal.show('addinstallappmodal')
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
      this.$bvModal.hide('addinstallappmodal')
    });
  },
  beforeMount() {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.dir = "rtl";
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.dir = "ltr";
      document.body.classList.remove("rtl");
    }
  },
  methods: {
    CheckPanier() {},
    GotoAboutUS() {
      this.$router.push({
        name: "aboutus"
      });
    },
    GotoPanier() {
      this.$router.push({
        name: "panier"
      });
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.$bvModal.hide('addinstallappmodal')
    },
    async install() {
      this.$bvModal.hide('addinstallappmodal')
      this.deferredPrompt.prompt();
    },
  },
  computed: {
    ...mapState("panierBadget", ["panierBadget"]),
    ...mapState("user", ["currentUser"])
  }
};
</script>

import axios from 'axios';
const apiURL = process.env.VUE_APP_API +"/website/";
const website = {
    namespaced:true,
    state:{
        website:null,
    },
    mutations:{
        SET_OR(state, data){
            state.website = data[0];
           
        },
       
    },
    actions:{
        load({commit}){
            axios.get(apiURL).then(res =>{
                commit('SET_OR', res.data);
            }).catch(err=>{
            })
        },     
    }
}
export default website;